import '../App.css';
import '../counter.css';
import React, { useState, useEffect } from 'react';
import whatsapp from '../assets/whatsapp.png';
import logo from '../assets/logo.png';
import Countdown from 'react-countdown';
import { bsc } from 'wagmi/chains'
import enter from '../assets/profile-user.png';
import { useNavigate } from 'react-router-dom'; // Import useHistory from React Router
import $ from "jquery";
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import cloudLeft from '../assets/cloud-left.png';
import cloudRight from '../assets/cloud-right.png';
import pulse from '../assets/pulse.png';

const homeLink = () => {
    window.open("#");
}

$(window).on('load', function () {
    setTimeout(function () { // allowing 3 secs to fade out loader
        $('.page-loader2').fadeOut('slow');
    }, 3000);
});

const Home = () => {
    const [zoomFactor, setZoomFactor] = useState(1);
    const [showLight, setShowLight] = useState(false);
    const navigate = useNavigate();
    const [_navbarOpen, set_navbarOpen] = useState(0);
    const [_pulseOff, setPulseOff] = useState(0);
    const [showClouds, setShowClouds] = useState(false);
    const [_newMarginLeft, setNewMarginLeft] = useState();

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }
 
    const handleZoom = () => {
        // Define the zoom factor here
        const newZoomFactor = 10; // Example: Zooming to a factor of 18
      setZoomFactor(newZoomFactor);

        setShowClouds(true);
        setPulseOff(1);    
        // Trigger the emission of golden light
        setTimeout(() => {
            setShowLight(true);
            // Navigate to the '/lands' route after the golden color animation finishes
            setTimeout(() => {
                navigate('/lands');
                //window.location.reload(true);
                console.log("Refreshing")
            }, 2000); // Navigate 3 seconds after the golden color animation starts
        }, 1000); // Start the light emission 3 seconds before the zoom animation ends
    };

    return (
        <div className="allWrap">
            <div class="page-loader2">
                <div class="parent-container">
                    <img className='logoLoad' src={logo} />
                </div>
            </div>
            <div className="light">

                <div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div class="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                            <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                        </div>

                    </div>

                </div>

                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div class="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                        <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>) : null}

                <div className='button' onClick={handleZoom}></div>
                {_pulseOff < 1 ?
                         <img class="containerC3" onClick={handleZoom} src={pulse}/>:null}
                 <img
                    className="mainBg"
                    src="https://imgs-dtn.pages.dev/imgs/homebg.png"
                    alt="Main Background Image"
                    style={{
                        transform: `scale(${zoomFactor})`,
                        transition: 'transform 9s ease', // Add transition for zooming effect
                    }}
                />
                {showLight && (
                    <div className="goldenLight"></div> // Golden light emitted from the middle
                )}
            </div>

            {showClouds && (
                  <>  <img
                        className="cloud-left"
                        src={cloudLeft}
                        alt="Cloud Left"
                       
                    />
                    <img
                        className="cloud-right"
                        src={cloudRight}
                        alt="Cloud Right"
                      
                    />
                    </>
            )}

            <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} /></div>

        </div>
    );
}

export default Home;

