import logo from '../assets/logo.png';
import section1Img from '../assets/IMG_2034.PNG';
import whatsapp from '../assets/whatsapp.png';
import React, { useState } from 'react';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import mail from '../assets/Layer-1-1.png';
import send from '../assets/message.png';

const ContactUs = () => {
    const [_navbarOpen, set_navbarOpen] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <div className='allwrapSocial'>
            <div className='allwrapAbout2'>
                <div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} onClick={() => window.location.href = '/'} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}><span className='onDiv'>Contact Us</span></div>
                            <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                        </div>

                    </div>

                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}><span className='onDiv'>Contact Us</span></div>
                        <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>) : null}

                <div className='aboutSection'>
                    <div class="card">
                        <img className='cardImg' alt="Illustration of an envelope with a letter and a paper plane"src={mail} />
                        <h5 class="card-title">
                            Stay tuned !
                        </h5>
                        <p class="card-text">
                            <span>Subscribe our newsletter and get notifications to stay update</span>
                        </p>
                        <div class="input-group">
                            <input class="form-control" placeholder="Enter your e-mail Address" type="email" />
                            <button class="btn" type="button">
                                <img className='send' src={send}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} /></div>

        </div>
    );
};

export default ContactUs;