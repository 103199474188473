import logo from '../assets/logo.png';
import section1Img from '../assets/IMG_2034.PNG';
import section2Img from '../assets/2A.png';
import section3Img from '../assets/3A.png';
import whatsapp from '../assets/whatsapp.png';
import React, { useState } from 'react';
import right from '../assets/right-arrow.png';
import left from '../assets/left-arrow.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';

const About = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [_navbarOpen, set_navbarOpen] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const detailsEggs = [
        {
            id: 'detailsEggSub',
            title: '3D Animation',
            description: 'Our institute offers comprehensive 3D animation courses designed to equip students with the skills and knowledge needed to excel in the animation industry. Our curriculum covers a wide range of topics, including character modeling, rigging, texturing, and animation principles. With state-of-the-art facilities and experienced instructors, students gain hands-on experience using industry-standard software and tools. Whether youre a beginner or looking to advance your skills, our courses provide a solid foundation and the creative environment needed to bring your animated visions to life. Join us to turn your passion for animation into a rewarding career.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/3danimation.PNG'
        },
        {
            id: 'detailsEggSub2',
            title: 'Game Development',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Game%20development.PNG'
        },
        {
            id: 'detailsEggSub3',
            title: 'Graphic Designer',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Graphicdesigner.PNG'
        },
        {
            id: 'detailsEggSub4',
            title: 'Sample',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/IMG_2007.png'
        },
        {
            id: 'detailsEggSub5',
            title: 'Sample',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/IMG_2008.png'
        },
        {
            id: 'detailsEggSub6',
            title: 'Metaverse',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Metaverse.PNG'
        },
        {
            id: 'detailsEggSub7',
            title: 'Mobile App Development',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Mobile%20app%20development.PNG'
        },
        {
            id: 'detailsEggSub8',
            title: 'Uiux',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Uiux.PNG'
        },
        {
            id: 'detailsEggSub9',
            title: 'Video Editing',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Videoediting.png'
        },
        {
            id: 'detailsEggSub10',
            title: 'Visual fx',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing LoremIpsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            imgSrc: 'https://eggs-75z.pages.dev/imgs/Visual%20fx.PNG'
        },
    ];

    const showNext = () => {
        setCurrentIndex((currentIndex + 1) % detailsEggs.length);
    };

    const showPrevious = () => {
        setCurrentIndex((currentIndex - 1 + detailsEggs.length) % detailsEggs.length);
    };

    const handleEggClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className='allwrapAbout'>
            <div className='allwrapAbout2'>
                <div className="headers">
                    <div className="headers2">
                        <div className="logo" onClick={() => window.location.href = '/'}>
                            <img className="logoPic" onClick={() => window.location.href = '/'} src={logo} alt="Logo" />
                        </div>
                        <div className="right">
                            <div className="navDiv" onClick={() => window.location.href = 'about'}><span className='onDiv'>About</span></div>
                            <div className="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div className="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                            <div className="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div className="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                            </div>
                    </div>
                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' onClick={() => window.location.href = '/'} src={logo} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div className="navDiv" onClick={() => window.location.href = 'about'}><span className='onDiv'>About</span></div>
                        <div className="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div className="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                        <div className="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div className="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>) : null}
                <div className='aboutSection'>
                    <div className='eachSections1' data-aos="zoom-in" data-aos-duration="1000">
                        <div className='eachSections-Sub'>
                            <div className='title'>About Us</div>
                            <p>Welcome to Createrse, a universe born at the crossroads of innovation and imagination. We are not merely an education, metaverse, and gaming company; we are the architects of new dimensions, where creativity breaks the bounds of reality. Since our inception, Createrse has been on an audacious journey, redefining what is possible in the realms of Animation, VFX, Game Development, and Immersive Technology.</p>
                            <p>Ours is a legacy over 20 years deep, yet our eyes are always on the horizon, charting paths through the uncharted landscapes of emerging tech. We dream in pixels, but we build in revolutions</p>
                        </div>
                        <div className='sec1Img'><img src={section1Img} alt="About Us" /></div>
                    </div>

                    <div className='eachSections2' data-aos="zoom-in" data-aos-duration="1000">
                        <div className='sec2Img'><img src={section2Img} alt="Mission" /></div>
                        <div className='eachSections-Sub'>
                            <div className='title' id="t2">Mission</div>
                            <p id="t2">Our mission is crystalline: to shatter the limitations of traditional creativity and empower individuals, dreamers, and industries alike to explore the endless possibilities of immersive technology. We seek to not only inspire but to equip creators with the tools to craft their own worlds—We are driven by the belief that education should be immersive, hands-on, and future-facing, empowering individuals to shape the digital landscapes of tomorrow. </p>
                            <p id="t2">Our purpose is to bend reality and hand it back, more vibrant and alive than ever before.</p>
                        </div>
                    </div>

                    <div className='eachSections3' data-aos="zoom-in" data-aos-duration="1000">
                        <div className='eachSections-Sub'>
                            <div className='title'>Values</div>
                            <p>Our values are the heartbeat of our work at Createrse. We believe in <span className="bold">creative sovereignty</span>—the idea that every individual should have the freedom and tools to shape their own narrative. We are driven by <span className="bold">integrity</span>, staying true to our mission while pushing the boundaries of technological advancement. We thrive on <span className="bold">collaboration</span>, knowing that the best ideas are born in the spaces between minds. At Createrse, we treat every challenge as an opportunity to create something new, something bold, something that didn’t exist yesterday. Because, for us, creation isn’t just a process—it’s the very essence of life.</p>
                         </div>
                        <div className='sec3Img'><img src={section3Img} alt="Values" /></div>
                    </div>
                </div>
            </div>

            <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} alt="WhatsApp" /></div>
        </div>
    );
};

export default About;
