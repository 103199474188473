import React, { useState } from 'react';
import game from '../assets/game.gif';
import whatsapp from '../assets/whatsapp.png';
import logo from '../assets/logo.png';
import navbar from '../assets/navigation-bar.png';
import table from '../assets/table.PNG';
import wrong from '../assets/wrong.png';
import mail from '../assets/Layer-1.PNG';
import greenEgg from '../assets/Layer-5.png';
import pinkEgg from '../assets/Layer-4.png';
import tableMb from '../assets/tablemb.png';
import player from '../assets/Layer-9.png';

const Services = () => {
    const [bookMoved, setBookMoved] = useState(false);
    const [_navbarOpen, set_navbarOpen] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    const bookClicked = () => {
        setBookMoved(!bookMoved); // Toggle the state

        // Optionally, if you want the image to return to its original position after a delay
        if (bookMoved) {
            setTimeout(() => {
                setBookMoved(false);
            }, 1000); // Reset the animation after 1 second
        }
    };

    const closeOverlay = () => {
        setBookMoved(false); // Close the overlay
    };

    return (
        <div className='tableMain'>
            <div className='tableMain2'>
                <div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} onClick={() => window.location.href = '/'} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}><span className='onDiv'>Services</span></div>
                            <div class="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                            <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                        </div>

                    </div>

                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}><span className='onDiv'>Services</span></div>
                        <div class="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                        <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>) : null}
                <div className='tableSet'>

                    <div
                        className="backgroundOverlay"
                        style={{ opacity: bookMoved ? 1 : 0 }}
                    >
                        <div className="close" onClick={closeOverlay}>✖</div>
                    </div>

                    <img
                        id='book'
                        className={`bookElement ${bookMoved ? 'moving' : ''}`}
                        src='https://lands-imgs.pages.dev/imgs/book-element.PNG'
                        alt="Book Element"
                        onClick={bookClicked}
                    />
                    <img className='game' src={game} />
                    <img className='mail' src={mail} onClick={() => window.location.href = 'contactus'}/>
                    <img className='greenEgg' src={greenEgg} />
                    <img className='pinkEgg' src={pinkEgg} />
                    <div class="containerC2" onClick={() => window.location.href = 'contactus'}>
                        <div class="pulse-background"></div>
                        <div class="pulse"></div>
                    </div>

                    <img className='tableMb' src={tableMb} alt="Table Background" />

                </div>

                <div className='tableSetMob'>
                    <div
                        className="backgroundOverlay"
                        style={{ opacity: bookMoved ? 1 : 0 }}
                    >
                        <div className="close" onClick={closeOverlay}>✖</div>
                    </div>

                    <img
                        id='book'
                        className={`bookElement ${bookMoved ? 'moving' : ''}`}
                        src='https://lands-imgs.pages.dev/imgs/book-element.PNG'
                        alt="Book Element"
                        onClick={bookClicked}
                    />

                    <img className='mail' src={mail} onClick={() => window.location.href = 'contactus'}/>

                    <img className='player' src={player} />

                </div>

                
                <div class="containerC2-2" onClick={() => window.location.href = 'contactus'}>
                        <div class="pulse-background"></div>
                        <div class="pulse"></div>
                    </div>

                <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} /></div>
                <img className='table' src={table} alt="Table Background" />

            </div>
        </div>
    );
}

export default Services;
