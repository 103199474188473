import HorizontalScroll from "../Components/original copy";
import React, { useState, useEffect } from 'react';
import Header from "../Components/headers";
import $ from "jquery";
import cloudLeft from '../assets/cloud-left.png';
import cloudRight from '../assets/cloud-right.png';
import enter from '../assets/enter.png';

const Land = () => {

  const [showClouds, setShowClouds] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [enterBtn, setEnter] = useState(0);

  async function handleEnterClick() {
    setEnter(1);  // This sets the state to 1 when clicked
  }

  return (
    <div className="headerLandsMain">

      {/*enterBtn < 1 ? (
        <div className="page-loader3">
          <img src={enter} alt="Enter" onClick={handleEnterClick} />
        </div>
      ) : (

        <>
          <img
            className="cloud-left-2"
            src={cloudLeft}
            alt="Cloud Left"
          />
          <img
            className="cloud-right-2"
            src={cloudRight}
            alt="Cloud Right"
          />
        </>)
      */}

      <Header />
      <HorizontalScroll />

    </div>
  );
};

export default Land;