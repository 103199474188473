import logo from '../assets/logo.png';

const Header = () => {
    return (
        <div>
            <div class="headersLands">
                <div class="headers2">

                    <div class="logo" onClick={() => window.location.href = '/'}>
                        <img class="logoPic" src={logo} />
                    </div>

                    <div class="right">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div className="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div className="navDiv" onClick={() => window.location.href = 'contactus'}>Contact Us</div>
                        <div className="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div className="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Header;