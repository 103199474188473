import logo from '../assets/logo.png';
import section1Img from '../assets/IMG_2034.PNG';
import whatsapp from '../assets/whatsapp.png';
import React, { useState } from 'react';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import mail from '../assets/Layer-1-1.png';
import send from '../assets/message.png';

const BuildVerse = () => {
    const [_navbarOpen, set_navbarOpen] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <div className='allwrapAbout'>
            <div className='allwrapAbout2'>
                <div class="headers">

                    <div class="headers2">

                        <div class="logo" onClick={() => window.location.href = '/'}>
                            <img class="logoPic" src={logo} onClick={() => window.location.href = '/'} />
                        </div>

                        <div class="right">
                            <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                            <div class="navDiv" onClick={() => window.location.href = 'services'}>Contact Us</div>
                            <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                            <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                        </div>

                    </div>

                </div>
                {_navbarOpen < 1 ?
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                    </div>) :
                    (<div class="miniBarMain">
                        <img className='logoPic' src={logo} onClick={() => window.location.href = '/'} />

                        <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                    </div>)}

                {_navbarOpen > 0 ?
                    (<div class="littleNav">
                        <div class="navDiv" onClick={() => window.location.href = 'about'}>About</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Services</div>
                        <div class="navDiv" onClick={() => window.location.href = 'services'}>Contact Us</div>
                        <div class="navDiv" onClick={() => window.location.href = 'learning'}>Learning</div>
                        <div class="navDiv" onClick={() => window.location.href = 'socials'}>Community</div>
                    </div>) : null}

                <div className='aboutSection'>
                    <div className='bv'>

  
                        <h1 className='colorTxt2'>BuildVerse: Redefining Real Estate & Construction with the Metaverse</h1>
                        <p className='colorTxt'><span className='colorTxt'>BuildVerse is a platform built for today’s real estate and construction industries. We blend digital technology, virtual reality (VR), and augmented reality (AR) to help developers, architects, and buyers visualise, build, and manage properties more efficiently and accurately—before a single brick is laid.</span></p>

                        <h2>What We Do</h2>
                        <p className='colorTxt'><span className='colorTxt'>BuildVerse creates fully interactive, customizable digital spaces that mimic real-world properties. Whether you’re selling, building, or managing a property, we offer tools that let you experience and modify these spaces with ease.</span></p>

                        <h2>Our Solutions</h2>
                        <ul>
                            <p>
                                <h3>1. Virtual Property Showcases</h3>
                                <p><span className='colorTxt'>Let your customers tour homes, offices, or buildings before construction begins. With lifelike virtual models, potential buyers can explore the layout, get a feel for the space, and even customise elements—all online.</span></p>
                            </p>
                            <p>
                                <h3>2. Real-Time Construction Tools</h3>
                                <p className='colorTxt'><span className='colorTxt'>Improve collaboration with tools that allow builders, architects, and engineers to spot design flaws and clashes in real time. This reduces costly mistakes and speeds up the construction process.</span></p>
                            </p>
                            <p>
                                <h3>3. Streamlined Property Management</h3>
                                <p className='colorTxt'><span className='colorTxt'>Keep properties in top shape using virtual walkthroughs for repairs and maintenance. Our platform allows property managers to track and manage facilities from anywhere, streamlining routine checks and maintenance requests.</span></p>
                            </p>
                            <p>
                                <h3>4. Personalization Made Simple</h3>
                                <p className='colorTxt'><span className='colorTxt'>Our marketplace lets buyers customize their future homes or offices directly in the digital model. From picking furniture to choosing finishes, everything can be adjusted to personal tastes before the project is completed.</span></p>
                            </p>
                        </ul>

                        <h2>Why BuildVerse?</h2>
                        <ul>
                            <p>
                                <h3>Sell Before You Build</h3> 
                                <p className='colorTxt'><span className='colorTxt'>Virtual tours help you secure sales faster by showing properties before they’re completed.</span></p>
                                </p>
                            <p>
                                <h3>Reduce Mistakes</h3> 
                                <p className='colorTxt'><span className='colorTxt'>Identify and resolve construction issues early with real-time collaboration tools.</span></p>
                                </p>
                            <p>
                                <h3>Easy Maintenance</h3> 
                                <p className='colorTxt'><span className='colorTxt'>Manage properties with virtual inspections and seamless repair tracking.</span></p>
                                </p>
                            <p>
                                <h3>Personalization at Your Fingertips</h3>
                                <p className='colorTxt'><span className='colorTxt'>Offer buyers easy customization options through a fully integrated platform.</span></p>
                                </p>
                        </ul>

                        <h2>Extra Features</h2>
                        <ul>
                            <p>
                                <h3>Augmented Reality Brochures</h3> 
                                <p className='colorTxt'><span className='colorTxt'>Bring your brochures to life. Customers can scan to view interactive 3D models of the property.</span></p>
                                </p>
                            <p>
                                <h3>1:1 Virtual Floor Plans</h3> 
                                <p className='colorTxt'><span className='colorTxt'>Walk through a life-size version of the property in VR to see exactly how it will feel in reality.</span></p>
                                </p>
                            <p>
                                <h3>360-Degree Videos</h3> 
                                <p className='colorTxt'><span className='colorTxt'>Provide immersive video tours with complete panoramic views.</span></p>
                                </p>
                            <p>
                                <h3>Interactive Digital Experiences</h3> 
                                <p className='colorTxt'><span className='colorTxt'>Add fun and engaging features, like games or projection mapping, to keep users invested in your project .</span></p>
                                </p>
                        </ul>

                        <h2>Built for Everyone in Real Estate and Construction</h2>
                        <p className='lastP'><span className='colorTxt'>Whether you’re a developer, architect, builder, or property manager, BuildVerse simplifies the process and boosts customer satisfaction with interactive, customizable, and efficient digital tools.</span></p>
                    </div>
                </div>
            </div>


            <div className='whatsappDiv'><img className='whatsapp' src={whatsapp} /></div>

        </div>
    );
};

export default BuildVerse;